import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useLanguage } from "./LanguageContext";

interface Translations {
  venues: Record<string, {
    title: string;
    description: string;
    capacity?: string;
  }>;
  experiences: Record<string, {
    title: string;
    description: string;
  }>;
  common: Record<string, string>;
}

interface TranslationsContextType {
  translations: Translations;
  isLoading: boolean;
  error: Error | null;
}

const TranslationsContext = createContext<TranslationsContextType | undefined>(undefined);

export function TranslationsProvider({ children }: { children: ReactNode }) {
  const [translations, setTranslations] = useState<Translations>({

    venues: {},
    experiences: {},
    common: {}
  });
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();
  const { language } = useLanguage();

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setIsLoading(true);
        
        // Fetch venues translations
        const { data: venuesData, error: venuesError } = await supabase
          .from('translations_venues')
          .select('*');
        
        if (venuesError) throw venuesError;

        // Fetch experiences translations
        const { data: experiencesData, error: experiencesError } = await supabase
          .from('translations_experiences')
          .select('*');
        
        if (experiencesError) throw experiencesError;

        // Fetch common translations
        const { data: commonData, error: commonError } = await supabase
          .from('translations_common')
          .select('*');
        
        if (commonError) throw commonError;

        // Process venues translations
        const venues: Record<string, any> = {};
        venuesData?.forEach(venue => {
          venues[venue.key] = {
            title: venue[`title_${language}`],
            description: venue[`description_${language}`],
            ...(venue[`capacity_${language}`] && { capacity: venue[`capacity_${language}`] })
          };
        });

        // Process experiences translations
        const experiences: Record<string, any> = {};
        experiencesData?.forEach(exp => {
          experiences[exp.key] = {
            title: exp[`title_${language}`],
            description: exp[`description_${language}`]
          };
        });

        // Process common translations
        const common: Record<string, string> = {};
        commonData?.forEach(item => {
          common[item.key] = item[`value_${language}`];
        });

        setTranslations({
          venues,
          experiences,
          common
        });
      } catch (err) {
        console.error('Error fetching translations:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch translations'));
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load translations"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchTranslations();
  }, [language, toast]);

  return (
    <TranslationsContext.Provider value={{ translations, isLoading, error }}>
      {children}
    </TranslationsContext.Provider>
  );
}

export function useTranslations() {
  const context = useContext(TranslationsContext);
  if (context === undefined) {
    throw new Error('useTranslations must be used within a TranslationsProvider');
  }
  return context;
}