import { useTranslations } from "@/contexts/TranslationsContext";
import { useGallery } from "@/contexts/GalleryContext";

import VenueCard from "./venues/VenueCard";
import BedroomCarousel from "./venues/BedroomCarousel";
import { useLanguage } from "@/contexts/LanguageContext";

const Features = () => {
  const { translations, isLoading } = useTranslations();
  const { language } = useLanguage();
  const { galleryImages } = useGallery();
  
  if (isLoading) {
    return (
      <section className="bg-white mt-20 md:mt-28">
        <div className="mx-auto">
          <div className="text-center">Loading...</div>
        </div>
      </section>
    );
  }

  const venues = [
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Hall.png",
      ...translations.venues.hall
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Screenshot%202025-01-14%20at%2003.40.52.png",
      ...translations.venues.gardens
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Screenshot%202025-01-14%20at%2003.39.30.png",
      ...translations.venues.terrace
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Atlas-view.png",
      ...translations.venues.mountain_view
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Screenshot%202025-01-14%20at%2003.40.59.png",
      ...translations.venues.pool
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Screenshot%202025-01-14%20at%2003.40.44.png",
      ...translations.venues.villa
    }
  ];

  return (
    <section className="bg-white mt-20 md:mt-28">
      <div className="mx-auto">
        <h2 className={`text-5xl text-center mb-16 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
          {translations.common.venues_title}
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 lg:gap-8 md:px-6 lg:px-8">
          {venues.map((venue, index) => (
            <VenueCard key={index} image={venue.image} venue={venue} />
          ))}
        </div>

        <BedroomCarousel 
          images={galleryImages} 
          title={{
            en: translations.common.bedrooms_title,
            fr: translations.common.bedrooms_title,
            nl: translations.common.bedrooms_title,
            ar: translations.common.bedrooms_title
          }} 
        />
      </div>
    </section>
  );
};

export default Features;